.get-in-touch {
  background: #f9f9f9;
  padding: 5rem 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.get-in-touch-main {
  display: flex;
  gap: 4rem;
}
.get-in-touch h1 {
  color: #111;
  text-transform: uppercase;
  font-size: 5rem;
  font-family: "Oswald", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 104%;
  letter-spacing: 2.55px;
  max-width: 500px;
  margin-right: 45rem;
}

.get-in-touch-offices {
  display: flex;
  gap: 1.4rem;
}

.get-in-touch-office h3 {
  color: #111;
  font-family: Oswald;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 187%;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  padding-top: 3rem;
}

.get-in-touch-office-text {
  width: 310px;
  color: #333;
  font-family: Raleway;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 187%;
  letter-spacing: 0.36px;
}

.get-in-touch-office-data {
  color: #111;
  font-family: Raleway;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.36px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.get-in-touch-office-get-location {
  color: #111;
  font-family: Raleway;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.36px;
  margin-top: 2rem;
  display: block;
  border-bottom: 2px solid #111;
  max-width: 108px;
  padding-bottom: 5px;
  cursor: pointer;
}
.get-in-touch-office-get-location:hover {
  border-bottom: 3px solid #111;
  padding-bottom: 2px;
}
.get-in-touch form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.get-in-touch form input {
  width: 500px;
  height: 3rem;
  border: none;
  border-bottom: 1px solid #111;
  background-color: #f9f9f9;
  color: #555;
  font-family: Raleway;
  font-size: 1.1rem;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.08px;
}
.get-in-touch form input:focus {
  outline: none;
}
.get-in-touch form button {
  background: #111;
  color: #fff;
  text-align: center;
  font-family: Oswald;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.44px;
  text-transform: uppercase;
  margin-top: 3rem;
  padding: 1rem 2rem;
  border: none;
  max-width: 140px;
  cursor: pointer;
}
.get-in-touch form button:hover {
  scale: 1.1;
}
@media (max-width: 1400px) { 
  .get-in-touch-main {
    flex-direction: column;
  }
  .get-in-touch h1 {
    margin-right: 10rem;
  }

  .get-in-touch{
    padding-left: 20px;
  }
}
  @media (max-width: 768px) { 
    .get-in-touch-offices{
      flex-direction: column;
    }
    .get-in-touch form input{
      width: 400px;
    }
    .get-in-touch h1{
        font-size: 3rem;
        letter-spacing: 1.2px;
        margin-right: 0;
    }

    .g-map{
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 90px;
      
    }

    .g-map iframe {
      border: none;
      border-radius: 20px;
     
      height: 320px;
    }

    .copyright{
      background-color: black;
      color: white;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  @media (max-width: 576px) { 
    .get-in-touch form input{
      width: 250px;
    }
    .get-in-touch form button{
      scale: .8;
    }
    .get-in-touch-offices{
      gap: 0;
    }
    .get-in-touch-offices{
      scale: .95;
    }
    .get-in-touch-main{
      gap: 1rem;
    }
    .get-in-touch h1{
      text-align: center;
      max-width: 290px;
    }
  }

