.hero {
  position: relative;
}
.hero img {
  width: 100%;
  
}
.hero h1 {
  font-family: Oswald;
  font-size: 10rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 22.4px;
  text-transform: uppercase;
  position: absolute;
  bottom: -4%;
  z-index: 10;
  margin-block-end: 0;
  -webkit-text-stroke: 2px #696969		;
    color: transparent;
    user-select: none;
}


@media (max-width: 1400px) { 
  .hero h1{
    bottom: -3.5%;
    font-size: 7.5rem;
  }

  .slider-item {
    position: relative;
  }
  
  .button-container {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 99;
  }
  .button-container button {
    width: 180px;
    height: 50px;
    border-radius: 30px;
    border: 1px rgb(111, 104, 104) solid;
    background-color: black;
    color: white;
    font-size: 15px;
  }

  .button-container button:hover{

    background-color: rgb(124, 124, 131);
    color: aliceblue;

  }

  
  .hero img {
    width: 100%;
    height: 600px;
    
  }
}
@media (max-width: 1200px) { 
  .hero h1{
    font-size: 5rem;
  }
}
@media (max-width: 992px) { 
  .hero h1{
    font-size: 4.5rem;
  }
}
@media (max-width: 768px) { 
  .hero h1{
    bottom: -2.4%;
    font-size: 3rem;
  }
}
@media (max-width: 576px) { 
  .hero h1{
    bottom: 0;
    -webkit-text-stroke: 1px #696969		;
    font-size: 2rem;
    letter-spacing: 9px;
  }
}
